<template>
  <div class="materialConfig">
    <component
      :is="currentCom"
      :mode="mode"
      :row-detail.sync="row"
      :options="options"
      @updateCom="updateCom"
      @update:row-detail="rowDetail = $event"
    />
  </div>
</template>
<script>
export default {
  components: {
    MaterialConfigList: (resolve) =>
      require(['./components/list.vue'], resolve),
    MaterialConfigAdd: (resolve) => require(['./components/add.vue'], resolve)
  },
  data() {
    return {
      currentCom: 'MaterialConfigList',
      mode: '',
      row: {},
      options: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row = {}, options, workNext }) {
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
      this.options = options
      console.log(this.currentCom, this.mode, this.row, options, workNext)
    }
  }
}
</script>
